import React from 'react';
import { colors } from './colors';
import styled from 'styled-components';
import MoonlightArtwork from './images/moonlight.png';
import Nonagone from './images/nonagone-white-1.svg';
import Spotify from './images/spotify.svg';
import Apple from './images/apple-music.png';

const Container = styled.div`
  background: ${colors.PURPLE}
  display: grid;
  grid-template-columns: 1.25fr 0.75fr 1.25fr;
  grid-template-rows: 1fr;
  grid-template-areas: '. . .';
  min-height: 100vh;
  max-height: 100vh;
  padding-left: 1em;
  padding-right: 1em;
`;  

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
`;

const StyledEmphasizedText = styled.a`
  text-decoration: none;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.5em;
  overflow: visible;
  color: black;
`;

const StyledEmphasizedParagraph = styled.p`
  & a:after {
    content: '';
    display: block;
    height: 8px;
    width: 98%;
    background: black;
    margin-top: -12px;
    margin-left: 4px;
  }
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // background-color: green;
`;

export class Sidebar extends React.Component {
  render = () => {
    return (
      <Side>

        <a href="https://open.spotify.com/artist/2Kfe0Z5tFVIPXun1mRE2Sb?si=JsDM9hpGS82sFaOomWL6vg">
          <img 
            src={Spotify} 
            className="App-logo" 
            alt="Spotify" 
            style={{ 
              minHeight: '50px', 
              minWidth: '50px', 
              maxHeight: '50px', 
              maxWidth: '50px', 
              marginBottom: '3em',
            }} 
          />
        </a>

        <a href="https://instagram.com/everythingisvega">
          <img 
            src={Nonagone} 
            alt="Nonagone" 
            style={{ 
              minHeight: '70px', 
              minWidth: '70px', 
              maxHeight: '70px', 
              maxWidth: '70px', 
              marginBottom: '3em',
            }} 
          />
        </a>

        <a href="https://music.apple.com/ca/artist/clay-vega/1477681758">
          <img 
            src={Apple} 
            className="App-logo" 
            alt="Apple Music" 
            style={{ 
              minHeight: '50px', 
              minWidth: '50px', 
              maxHeight: '50px', 
              maxWidth: '50px', 
            }} 
          />
        </a>
      </Side>
    )
  }
}

export class PhotoPage extends React.Component {
  props: {
    background: String,
    img: Image,
  }
  
  render = () => {
    return (
      <Container style={{ background: this.props.background }}>
        <Header />
        <Header>
          <img src={this.props.img} style={{ maxWidth: '50vw', maxHeight: '50vh' }} alt="clay" />
        </Header>
        <Header />
      </Container>
    );
  };
}

const FooterContainer = styled.section`
  position: fixed;
  left: 0;
  top: 50%; 
  transform: translate(-50%, -50%);
  max-height: 100vh;
  padding-left: 6em;
  
  // @media only screen and (min-width: 540px) {
  //   max-width: 50%;
  // }
`;

const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default class Gallery extends React.Component {
  render = () => {
    return (
      <div style={{ minHeight: '100vh', minWidth: '100vw', backgroundColor: 'black'}}>
        <FooterContainer>
          <Sidebar/>
        </FooterContainer>
        <CenteringContainer> 
        <img src={MoonlightArtwork} 
          alt="for-you-artwork"
          style={{
            minWidth: '100vw',
            maxWidth: '100%',
          }}
        />
        <img src={MoonlightArtwork} 
          alt="for-you-artwork"
          style={{
            minWidth: '100vw',
            maxWidth: '100%',
          }}
        />
        <img src={MoonlightArtwork} 
          alt="for-you-artwork"
          style={{
            // minHeight: '100vh',
            minWidth: '100vw',
            maxWidth: '100%',
          }}
        />
        <img src={MoonlightArtwork} 
          alt="for-you-artwork"
          style={{
            // minHeight: '100vh',
            minWidth: '100vw',
            maxWidth: '100%',
          }}
        />
        <img src={MoonlightArtwork} 
          alt="for-you-artwork" 
          style={{
            minWidth: '100vw',
            maxWidth: '100%',
          }}
        />
        <img src={MoonlightArtwork} 
          alt="for-you-artwork"
          style={{
            minWidth: '100vw',
            maxWidth: '100%',
          }}
        />
        {/* <PhotoPage background={colors.YELLOW} img={Clay} /> */}
        </ CenteringContainer> 
      </div>
    );
  }
}
