import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import LandingPage from './Landing';

const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  line-height: 1.5;
  // padding: 2em;
`;

const App = () => (
  <Router>
    <Container>
      <Route exact path="/" component={LandingPage} />
    </Container>
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
